import React from 'react'

// Constants & Config

// Components

// Route
import MainRoutes from "./services/navigation/route/main";

// Service

// styles
import './index.css';

const App = (props) => {

    return (
        <>
            <MainRoutes {...props} />
        </>
    )
}

export default React.memo(App)