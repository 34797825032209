// Third-party Library
import { useState } from "react";
import { Modal, Steps } from "antd";

// Components
import { ButtonLink, Img } from "../../../../../../../../components";

// Services
import { TIME } from "../../../../../../../../services/util";

// Styles
import classes from "../../../../index.module.css";

const TimelineList = ({ orderLogs, ORDER_STATUS_NAME }) => {
  const { olog } = orderLogs;

  // Preview Image Modal state
  const [previewImg, setPreviewImg] = useState({
    isOpen: false,
    attachment: [],
  });

  const Title = ({ status }) => {
    return (
      <div className={`${classes["timeline-title"]}`}>
        <h1>{ORDER_STATUS_NAME(status)}</h1>
      </div>
    );
  };

  const Description = ({ remark, attachment, updatedAt }) => {
    return (
      <div className={classes["timeline-subtitle"]}>
        <p className={classes["time"]}>
          {TIME.parseTimeMinDayFirst(updatedAt)}
        </p>
        <p>Remarks: {remark}</p>
        <div className={classes["attachment"]}>
          <p>Attachment: </p>
          {attachment?.length > 0 ? (
            <ButtonLink
              onClick={() => setPreviewImg({ attachment, isOpen: true })}
            >
              View
            </ButtonLink>
          ) : (
            "-"
          )}
        </div>
      </div>
    );
  };

  // array used for Steps
  const ologItems = olog.slice(1).map((ologItem) => {
    const { status, updatedAt, remark, attachment } = ologItem;

    return {
      title: <Title status={status} />,
      description: (
        <Description
          remark={remark}
          attachment={attachment}
          updatedAt={updatedAt}
        />
      ),
    };
  });

  return (
    <>
      <Modal
        footer={null}
        open={previewImg.isOpen}
        onCancel={() => setPreviewImg({ attachment: [], isOpen: false })}
      >
        {previewImg.attachment.map((src, i) => (
          <div key={i} style={{ marginBottom: "2em", textAlign: "center" }}>
            <Img src={src.url} key={i} />
          </div>
        ))}
      </Modal>
      <h1 className={classes["header-timeline"]}>Order Timeline</h1>
      <Steps
        direction="vertical"
        items={ologItems}
        current={ologItems.length}
        className={classes["custom-step"]}
        // responsive
      />
    </>
  );
};

export default TimelineList;
