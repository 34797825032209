// Third-party Library
import { useNavigate } from "react-router";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { COMMON_FEATURES, ROUTES } from "../../../../constants";

// Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles
import classes from "../../components/OrderStatus/index.module.css";
import { ButtonLink } from "../../../../components";

const TrackHeader = ({ ordid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backBtn = () => {
    dispatch(REDUX_ACTION.v1_order.remove_order_thru_ordid());
    dispatch(REDUX_ACTION.v1_vehicle.remove_vehicle_thru_plate());
    navigate(ROUTES.MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TRACKING]);
  };

  return (
    <span className={classes["header-track"]}>
      <ButtonLink onClick={backBtn}>
        <LeftOutlined />
        <span style={{ marginLeft: "5px", color: "black" }}>{ordid}</span>
      </ButtonLink>
    </span>
  );
};

export default TrackHeader;
