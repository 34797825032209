import React from "react";
import { Result } from 'antd';

/**
 * Indicates no data is available.
 */
export const NotFoundPage = () => {
    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        </>
    );
};


export default NotFoundPage;