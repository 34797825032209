import { combineReducers } from "redux";

/**
 * Root Redux Reducer
 */

/**
 * Containized Redux Reducer
 */
import { v1Reducer } from '../../../containers/v1/services/redux/reducers'

const appReducer = () => combineReducers({
  containers: combineReducers({
    v1: v1Reducer
  }),
    // ... // rest of your reducers
})

export const rootReducer = (history) => (state, action) => {

    
  return appReducer(history)(state, action);
}