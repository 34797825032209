import * as ActionTypes from "../action-types";

const vehicle_defaultState = {};

export const vehicle = (state = vehicle_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_VEHICLE_THRU_PLATE: {
      const plate = action.plate;
      const date = action.date;
      const vehicle = action.vehicle;

      newState = { plate, updatedAt: date, ...vehicle };

      return newState;
    }
    case ActionTypes.REMOVE_VEHICLE_THRU_PLATE: {
      return vehicle_defaultState;
    }

    default: {
      return state;
    }
  }
};
