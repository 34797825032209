export const SET_LOADING_GET_TRACK_THRU_ORDID =
  "V1_SET_LOADING_GET_TRACK_THRU_ORDID";
export const UNSET_LOADING_GET_TRACK_THRU_ORDID =
  "V1_UNSET_LOADING_GET_TRACK_THRU_ORDID";

export const SET_LOADING_GET_VEHICLE_THRU_PLATE =
  "V1_SET_LOADING_GET_VEHICLE_THRU_PLATE";
export const UNSET_LOADING_GET_VEHICLE_THRU_PLATE =
  "V1_UNSET_LOADING_GET_VEHICLE_THRU_PLATE";

export const SET_LOADING_ORDER_SUBMIT = "V1_SET_LOADING_ORDER_SUBMIT";
export const UNSET_LOADING_ORDER_SUBMIT = "V1_UNSET_LOADING_ORDER_SUBMIT";
