import * as ActionTypes from "../action-types";

const order_defaultState = {};

export const order = (state = order_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_ORDER_THRU_ORDID: {
      const order = action.order;

      newState = order;

      return newState;
    }

    case ActionTypes.REMOVE_ORDER_THRU_ORDID: {
      return order_defaultState;
    }

    default: {
      return state;
    }
  }
};
