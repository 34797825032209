// Third-party Library
import { message } from "antd";

import * as API from "../../api";
import * as ActionTypes from "../action-types";
import {
  setLoadingGetTrackThruOrdid,
  unsetLoadingGetTrackThruOrdid,
} from "./style";

export const get_order_thru_ordid_request = (ordid) => (dispatch) => {
  dispatch(setLoadingGetTrackThruOrdid());
  API.getOrderThruOrdid({
    ordid,
  })
    .then((info) => {
      const { status, msg, order } = info;

      if (status === 200) {
        message.success(msg);
        dispatch(get_order_thru_ordid_success(order));
      } else {
        message.warning(msg);
      }
    })
    .finally(() => {
      dispatch(unsetLoadingGetTrackThruOrdid());
    });
};

const get_order_thru_ordid_success = (order) => {
  return {
    type: ActionTypes.GET_ORDER_THRU_ORDID,
    order,
  };
};

export const remove_order_thru_ordid = () => {
  return {
    type: ActionTypes.REMOVE_ORDER_THRU_ORDID,
  };
};
