// Third-party Library
import { useLocation } from "react-router";
import { FloatButton } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

// Constants Library
import { APP_INFO } from "../../../../config";

// Components
import { SearchInput, Timeline } from "./components";

// Styles
import common from "./index.module.css";

const OrderStatus = (props) => {
  const { ordid } = props;
  // const location = useLocation();

  // const searchIsEmpty = location.search === "";
  // const ordid = new URLSearchParams(location.search).get("ordid");

  return (
    <>
      <div className={common["container"]}>
        {/* {searchIsEmpty ? <SearchInput /> :  */}
        <Timeline ordid={ordid} />
        {/* } */}
      </div>
      <FloatButton
        type="success"
        shape="circle"
        style={{ position: "fixed", right: "8vw", backgroundColor: "#7AD06D" }}
        icon={<WhatsAppOutlined style={{ color: "#fff" }} />}
        onClick={() => window.open(APP_INFO.ASSET.WHATSAPP_NUMBER, "_blank")}
      />
    </>
  );
};

export default OrderStatus;
