// Third-party Library 
import { useNavigate } from 'react-router';

// Config
import { APP_INFO } from '../../config'

// Constant
import { ROOT_CONSTANT } from '../../constants'

// Styles
import classes from "./index.module.css";

const { IPICK_LOGO } = ROOT_CONSTANT.IMG.ICON
const { ROUTE } = ROOT_CONSTANT

const Header = () => {
    const navigate = useNavigate()

    return (
        <div className={classes.navbar}>
            <img src={IPICK_LOGO} className={classes["icon-img"]} alt="" />
            <p
                role="presentation"
                className={classes.tagline}
                onClick={() => navigate(ROUTE.MODULE_ROUTE.Landing_Page)}
            >
                Public Portal <span className={classes.version}>v {APP_INFO.APP_VERSION}</span>
            </p>
      </div>
    )
}

export default Header