import * as ActionTypes from "../action-types"

const orderLog_defaultState = {
    olog: [],
    isOrderLogActive: null
}

export const orderLog = (state = orderLog_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch(action.type) {
        case ActionTypes.GET_TRACK_THRU_ORDID: {
            const orderLogs = action.orderLogs

            const origid = action.origid
            const dstid = action.dstid
            const origpid = action.origpid
            const dstpid = action.dstpid

            newState.olog = orderLogs.sort((a, b) => b.updatedAt - a.updatedAt)
            newState.isOrderLogActive = orderLogs.length > 0 ? true : false

            newState.origid = origid
            newState.dstid = dstid
            newState.origpid = origpid
            newState.dstpid = dstpid

        

            return newState
        }
        
        default: {
            return state
        }
    }
}