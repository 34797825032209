import React from "react";
import { Routes, Route, useLocation } from "react-router";

// Constant
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components
import { asyncComponent } from "../../../../components";
import { ROOT_PAGES } from "../../../../pages";

// Components
import Tracking from "../../../../pages/Tracking";

// Modules
const { MODULE_ROUTE } = ROUTES;

const AsyncNotFoundPage = asyncComponent(() => <ROOT_PAGES.NotFoundPage />);

const MainRoutes = () => {
  const location = useLocation();

  const { state = {} } = location;

  const ordid = state && state.ordid;

  return (
    <Routes>
      <Route element={<AsyncNotFoundPage />} />

      <Route
        exact
        path={MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TRACKING]}
        element={<Tracking />}
      />
    </Routes>
  );
};
export default MainRoutes;
