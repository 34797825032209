import * as ActionTypes from "../action-types/style";

export const setLoadingGetTrackThruOrdid = () => {
  return {
    type: ActionTypes.SET_LOADING_GET_TRACK_THRU_ORDID,
  };
};

export const unsetLoadingGetTrackThruOrdid = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GET_TRACK_THRU_ORDID,
  };
};

export const setLoadingGetVehicleThruPlate = () => {
  return {
    type: ActionTypes.SET_LOADING_GET_VEHICLE_THRU_PLATE,
  };
};

export const unsetLoadingGetVehicleThruPlate = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GET_VEHICLE_THRU_PLATE,
  };
};
