// Third-party Library
import { message } from "antd";

import * as API from "../../api";
import * as ActionTypes from "../action-types";

import {
  setLoadingGetVehicleThruPlate,
  unsetLoadingGetVehicleThruPlate,
} from "./style";

export const get_vehicle_thru_plate_request = (plate, date) => (dispatch) => {
  dispatch(setLoadingGetVehicleThruPlate());

  API.getVehicleThruPlate({
    plate,
  })
    .then((info) => {
      const { status, msg, vehicleState } = info;

      if (status === 200) {
        message.success(msg);
        dispatch(get_vehicle_thru_plate_success(plate, date, vehicleState));
      } else {
        message.warning(
          "Live tracking is not currently available for this vehicle. Please contact our customer support for further assistance."
        );
      }
    })
    .finally(() => {
      dispatch(unsetLoadingGetVehicleThruPlate());
    });
};

const get_vehicle_thru_plate_success = (plate, date, vehicle) => {
  return {
    type: ActionTypes.GET_VEHICLE_THRU_PLATE,
    plate,
    date,
    vehicle,
  };
};

export const remove_vehicle_thru_plate = () => {
  return {
    type: ActionTypes.REMOVE_VEHICLE_THRU_PLATE,
  };
};
