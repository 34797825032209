import { combineReducers } from "redux";

// V1 Reducer
import { style } from "./style";
import { orderLog } from "./orderLog";
import { vehicle } from "./vehicle";
import { order } from "./order";

export const v1Reducer = combineReducers({
  style,
  orderLog,
  vehicle,
  order,
});
