/**
 * Logo
 */
 import logo from '../../assets/logo/logo.png'
 import logo_title_svg from '../../assets/logo/logo_title_white.svg'
 
 /**
  * Background
  */

import * as THEME from '../../assets/theme/color'
 
 
 /**
  * Color Theme
  */
const ThemeColor = THEME.color
const ThemeText = THEME.text
 
const img = {
     logo: logo,
     logo_title_svg: logo_title_svg
}
 
const color = {
     themeColor: ThemeColor,
     themeText: ThemeText
}
 
export const LOGO = img.logo;
export const LOGO_TITLE_SVG = img.logo_title_svg;
 
export const THEME_COLOR = color.themeColor;
export const TEXT_COLOUR = color.themeText;