// Third-party Library
import { message } from "antd";

import * as API from "../../api";
import * as ActionTypes from "../action-types";

import {
  setLoadingGetTrackThruOrdid,
  unsetLoadingGetTrackThruOrdid,
} from "./style";

export const get_track_thru_ordid_request =
  (ordid, back = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingGetTrackThruOrdid());

    API.getTrackThruOrdid({
      ordid,
    })
      .then((info) => {
        const {
          status,
          msg,
          orderLogs,
          origid,
          dstid,
          origpid = null,
          dstpid = null,
        } = info;

        if (status === 200) {
          message.success(msg);
          dispatch(
            get_track_thru_ordid_success(
              orderLogs,
              origid,
              dstid,
              origpid,
              dstpid
            )
          );
        } else {
          message.warning(msg);
          back();
        }
      })
      .finally(() => {
        dispatch(unsetLoadingGetTrackThruOrdid());
      });
  };

const get_track_thru_ordid_success = (
  orderLogs,
  origid,
  dstid,
  origpid,
  dstpid
) => {
  return {
    type: ActionTypes.GET_TRACK_THRU_ORDID,
    orderLogs,
    origid,
    dstid,
    origpid,
    dstpid,
  };
};
