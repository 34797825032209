// Third-party Library
import { Tag, Carousel } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../../../constants";

// Components
import { Img } from "../../../../../../../../components";

// Services
import { TIME } from "../../../../../../../../services/util";

// Styles
import classes from "../../../../index.module.css";

const { ORDER_STATUS_INDEX } = TEMPLATE.ORDER_STATUS;

const TimelineHightlight = ({ orderLogs, ORDER_STATUS_NAME }) => {
  const { olog } = orderLogs;
  const { status = "", remark = "", attachment = "", updatedAt } = olog[0];

  return (
    <div className={classes["highlight-timeline"]}>
      <div>
        {attachment.length > 0 ? (
          <Carousel autoplay autoplaySpeed={6000}>
            {attachment.map((src, i) => (
              <Img src={src.url} key={i} />
            ))}
          </Carousel>
        ) : (
          <Img />
        )}
      </div>
      <div style={{ position: "relative" }}>
        <div className={classes["timeline-font"]}>
          <h1>Status</h1>
          <Tag
            color={ORDER_STATUS_INDEX[status].colorTag}
            style={{ fontSize: "10px" }}
          >
            {ORDER_STATUS_NAME(status)}
          </Tag>
        </div>
        <div className={classes["timeline-font"]}>
          <h1>Status Remarks</h1>
          <p>{remark}</p>
        </div>
        <p
          style={{
            fontSize: "11.8px",
            color: "#969696",
            position: "absolute",
            bottom: "1px",
            width: "18em",
          }}
        >
          Last updated at: {TIME.parseTimeMinDayFirst(updatedAt)}
        </p>
      </div>
    </div>
  );
};

export default TimelineHightlight;
