// Third-party Library
import { useNavigate } from "react-router";
import { Input, Form, Button } from "antd";

// Constants
import { ROUTES, COMMON_FEATURES } from "../../../../../../constants";

// Styles
import classes from "../../index.module.css";

const { MODULE_ROUTE } = ROUTES;

const SearchInput = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = ({ ordid }) => {
    navigate(
      MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TRACKING_TIMELINE] + ordid
    );
  };
 
  return (
    <>
      <>
        <h1 className={classes["title"]}>Track Your Order</h1>
        <p className={classes["subtitle"]}>
          Track your order by entering your order ID
        </p>
      </>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name={"ordid"}
          rules={[
            {
              required: true,
              message: "Order ID is required",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={classes["btn-submit"]}
            block
          >
            Search
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SearchInput;
