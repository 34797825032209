import * as CONFIG from "../../../config";

export const pt_v1_fe_path = CONFIG.APP_CONFIG.PT_V1_FE_API_GATEWAY;
export const headers = {
  Accept: "*/*",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
};

// export const pt_v1_fe_vehicle_path = CONFIG.APP_CONFIG.PT_VEHICLE_API_GATEAWAY;
// export const headerMap = {
//   Accept: "*/*",
//   "Content-Type": "application/json; charset=utf-8",
//   "Access-Control-Allow-Origin": "*",
//   apiKey: "ap_LBlF8qOphrm5kblSk51H0CJI",
// };
