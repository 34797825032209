import * as ActionTypes from "../action-types";

// Constants & Config

// Services

const STYLES_INITIAL_STATE = {
  loading: {
    track: false,
    vehicle: false,
    order_submit: false,
  },
};

export const style = (state = STYLES_INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_GET_TRACK_THRU_ORDID: {
      newState.loading.track = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GET_TRACK_THRU_ORDID: {
      newState.loading.track = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_GET_VEHICLE_THRU_PLATE: {
      newState.loading.vehicle = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GET_VEHICLE_THRU_PLATE: {
      newState.loading.vehicle = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = false;
      return newState;
    }

    default: {
      return state;
    }
  }
};
