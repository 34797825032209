import { useRef, useEffect } from 'react' 

export const reduxStoreWrapper = (keys) => (state) => {

    let returnState = state.containers.v1

    const keyArr = keys.split('.')

    keyArr.map(k => {
        returnState = returnState && returnState[k] || (Object.prototype.hasOwnProperty.call(returnState, k) ? returnState[k] : null)
        return 1
    })
        
    return returnState
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}