// Third-party Library
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { ROUTES, COMMON_FEATURES, TEMPLATE } from "../../../../../../constants";

// Components
import { LoadingPage } from "../../../../../../components";
import { TimelineHightlight, TimelineList, Expired } from "./components";

// Service
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Actions
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles
import classes from "../../index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL; 

const { MODULE_ROUTE } = ROUTES;

const { ORDER_STATUS_INDEX } = TEMPLATE.ORDER_STATUS;

const Timeline = ({ ordid }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(reduxStoreWrapper("style.loading.track"));
  const orderLogs = useSelector(reduxStoreWrapper("orderLog"));

  const {
    olog: orderLog,
    isOrderLogActive,
    origid,
    dstid,
    origpid = "",
    dstpid = "",
  } = orderLogs;

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_orderLog.get_track_thru_ordid_request(ordid, () =>
        navigate(MODULE_ROUTE[COMMON_FEATURES.FEATURE_NAME.TRACKING])
      )
    );
    dispatch(REDUX_ACTION.v1_order.get_order_thru_ordid_request(ordid));
  }, [dispatch]);

  const ORDER_STATUS_NAME = (status) => {
    let status_name = ORDER_STATUS_INDEX[status]?.name;

    if (status_name.includes("hub")) {
      if (status_name.includes("origin")) {
        status_name = status_name.replace("*", origid || "-");
      } else {
        status_name = status_name.replace("*", dstid || "-");
      }
    } else {
      if (status_name.includes("origin")) {
        status_name = status_name.replace("*", origpid || "-");
      } else {
        status_name = status_name.replace("*", dstpid || "-");
      }
    }

    return status_name;
  };

  const TimelineMain = () => {
    return (
      <div className={classes["container-track"]}>
        <TimelineHightlight
          orderLogs={orderLogs}
          ORDER_STATUS_NAME={ORDER_STATUS_NAME}
        />
        <TimelineList
          orderLogs={orderLogs}
          ORDER_STATUS_NAME={ORDER_STATUS_NAME}
        />
      </div>
    );
  };

  return (
    <>
      {
        // is Loading in progress, load LoadingPage
        loading ? (
          <LoadingPage />
        ) : // is orderLog return values, show TimelineStep page
        isOrderLogActive && orderLog.length ? (
          <TimelineMain orderLog={orderLog} ordid={ordid} />
        ) : (
          <Expired />
        )
      }
    </>
  );
};

export default Timeline;
