export const ORDER_STATUS = {
    SUBMITTED: "O0100",
    PENDING_SELF_LODGE: "O0201",
    PENDING_PICK_UP_FROM_CUSTOMER: "O0202",
    PENDING_PICK_UP_FROM_ORIGIN_POINT: "O0203",
    ARRIVED_AT_ORIGIN_HUB: "O0300",
    DEPARTED_FROM_ORIGIN_HUB: "O0400",
    PENDING_SELF_COLLECTION: "O0501",
    PENDING_DOORSTEP_DELIVERY: "O0502",
    ARRIVED_AT_DESTINATION_HUB: "O0503",
    FULFILLED: "O0600",
    PENDING_SELF_LODGE_POINT: 'O1200',
    ARRIVED_AT_ORIGIN_POINT: 'O1300',
    DEPARTED_FROM_ORIGIN_POINT: 'O1400',
    PENDING_SELF_COLLECTION_POINT: 'O1500',
    COMPLETED: "O2000",
    CLOSED: "O4100",
    PARKED: "O4200",
    CANCELLED: "O4300",
  };

export const ORDER_STATUS_INDEX = {
    [ORDER_STATUS.SUBMITTED]: {
        name: "Submitted successfully",
        colorTag: "default",
        type: 'ONGOING'
    },
    [ORDER_STATUS.PENDING_SELF_LODGE]: {
        name: "Pending self lodge to origin hub (H*)",
        colorTag: "blue",
        type: "ONGOING"
    },
    [ORDER_STATUS.PENDING_SELF_LODGE_POINT]: {
        name: "Pending self lodge to origin point (P*)",
        colorTag: "blue",
        type: "ONGOING"
    },
    [ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER]: {
        name: "Pending pick up",
        colorTag: "blue",
        type: 'ONGOING'
    },
    [ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB]: {
        name: "Received at origin hub (H*)",
        colorTag: "blue",
        type: 'ONGOING'
    },
    [ORDER_STATUS.PENDING_PICK_UP_FROM_ORIGIN_POINT]: {
        name: "Departing to origin hub (H*)",
        colorTag: "blue",
        type: "ONGOING"
    },
    [ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT]: {
        name: "Received at origin point (P*)",
        colorTag: "blue",
        type: 'ONGOING'
    },
    [ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB]: {
        name: "Departing to destination hub (H*)",
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT]: {
        name: "Departing to destination point (P*)",
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.PENDING_SELF_COLLECTION]: {
        name: "Arrived & ready for self collection",
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB]: {
        name: "Arrived at destination hub (H*)",
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.PENDING_SELF_COLLECTION_POINT]: {
        name: "Arrived & ready for self collection",
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.PENDING_DOORSTEP_DELIVERY]: {
        name: 'Arrived & ready for delivery',
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.FULFILLED]: {
        name: 'Received by receiver',
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.COMPLETED]: {
        name: 'Completed',
        colorTag: 'green',
        type: 'DONE'
    },
    [ORDER_STATUS.CLOSED]: {
        name: 'Closed',
        colorTag: 'green',
        type: 'DONE'
    },
    [ORDER_STATUS.PARKED]: {
        name: 'Parked',
        colorTag: 'blue',
        type: 'ONGOING'
    },
    [ORDER_STATUS.CANCELLED]: {
        name: 'Cancelled',
        colorTag: 'red',
        type: 'CANCELLED'
    }
}