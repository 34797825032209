// Constants
import { ROOT_CONSTANT } from "../../../../../../../../constants";

// Styles
import classes from "../../../../index.module.css";

const { icon_time_expired } = ROOT_CONSTANT.IMG.ICON;

const Expired = () => {
  return (
    <>
      <span className={classes["expired-title"]}>
        <img src={icon_time_expired} alt="time expired icon" />
        <h1>
          <b>Track has expired</b>
        </h1>
      </span>
      <p className={classes["subtitle"]}>
        The link that you’ve entered has expired.
      </p>
    </>
  );
};

export default Expired;
