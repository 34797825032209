// Third-party Library
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Constants Library
import { APP_CONFIG } from "../../../../config";

// Components

// Service
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles
import classes from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { currentTimeMilliSecond, parseTime } = TIME;

const OrderLocation = (props) => {
  const { ordid } = props;

  const dispatch = useDispatch();

  const order = useSelector(reduxStoreWrapper("order"));
  const vehicle = useSelector(reduxStoreWrapper("vehicle"));
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_vehicle.get_vehicle_thru_plate_request(
        order.v_plate,
        currentTimeMilliSecond()
      )
    );
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        REDUX_ACTION.v1_vehicle.get_vehicle_thru_plate_request(
          order.v_plate,
          currentTimeMilliSecond()
        )
      );
    }, 30000);

    return () => clearInterval(interval);
  }, [dispatch, order.v_plate]);

  useEffect(() => {
    if (vehicle?.location?.lat && vehicle?.location?.lon) {
      getAddressFromCoordinates(vehicle.location.lat, vehicle.location.lon);
    }
  }, [vehicle]);

  const toggleInfoWindow = () => {
    setInfoWindowOpen(!infoWindowOpen);
  };

  const coordinates = {
    lat: vehicle?.location?.lat,
    lng: vehicle?.location?.lon,
  };

  //function to get the coordinate
  const getAddressFromCoordinates = (lat, lng) => {
    if (!window.google || !window.google.maps) {
      setTimeout(() => getAddressFromCoordinates(lat, lng), 100);
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat, lng };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        } else {
          setAddress("Address not found");
        }
      } else {
        setAddress("Geocoder failed due to: " + status);
      }
    });
  };

  //modal for detail vehicle
  const DetailsInfoWindow = () => {
    return (
      <div className={classes["infoWindow-container"]}>
        <Row
          style={{ fontSize: "20px", marginBottom: "20px" }}
          justify="space-between"
        >
          <Col>
            <h3>{vehicle?.plate}</h3>
            <p style={{ color: "GrayText" }}>
              Last updated at {parseTime(vehicle?.updatedAt)}
            </p>
          </Col>
        </Row>
        <Col>
          <h5>Location</h5>
          <p>{address}</p>
        </Col>
      </div>
    );
  };

  return (
    <>
      {APP_CONFIG.API_KEY.GOOGLE_MAP && (
        <LoadScript googleMapsApiKey={APP_CONFIG.API_KEY.GOOGLE_MAP}>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "80vh",
            }}
            center={coordinates}
            zoom={14}
          >
            <Marker
              position={coordinates}
              onClick={toggleInfoWindow}
              // onLoad={onPlaceLoad}
            >
              {infoWindowOpen && (
                <InfoWindow onCloseClick={toggleInfoWindow}>
                  <DetailsInfoWindow />
                </InfoWindow>
              )}
            </Marker>
          </GoogleMap>
        </LoadScript>
      )}
    </>
  );
};

export default OrderLocation;
