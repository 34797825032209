// Third-party Library
import { Tabs } from "antd";
import { useLocation } from "react-router";

// Constants

// Components
import { OrderStatus, OrderLocation, TrackHeader } from "./components";
import { SearchInput } from "./components/OrderStatus/components";
import { Header } from "../../components";

// Styles
import classes from "../index.module.css";
import "./index.css";

const Tracking = () => {
  const location = useLocation();

  const searchIsEmpty = location.search === "";
  const ordid = new URLSearchParams(location.search).get("ordid");
  const items = [
    {
      key: "1",
      label: <span>Order Status</span>,
      children: <OrderStatus ordid={ordid} />,
    },
    {
      key: "2",
      label: "Track Order Location",
      children: <OrderLocation ordid={ordid} />,
    },
  ];

  return (
    <div className={classes.wrapDiv}>
      <Header />
      {searchIsEmpty ? (
        <div className={classes.container}>
          <SearchInput />
        </div>
      ) : (
        <div className={classes.container}>
          <TrackHeader ordid={ordid} />
          <Tabs
            defaultActiveKey="1"
            items={items}
            tabBarStyle={{ width: "100%", zIndex: 5 }}
          />
        </div>
      )}
    </div>
  );
};

export default Tracking;
